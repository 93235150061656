<template>
  <div id="map" style="width: 100%; height: 400px;"></div>
</template>
 
<script>
export default {
  name: 'BaiduMapComponent',
  data() {
    return {
      map: null,
      overlays: []
    };
  },
  mounted() {
    this.initMap();
    this.addZoomEye();
    this.simulateRealTimeTrack();
  },
  methods: {
    initMap() {
      this.map = new BMap.Map('map');
      this.map.enableScrollWheelZoom(true);     // 开启鼠标滚轮缩放
      this.map.centerAndZoom(new BMap.Point(116.404, 39.915), 11);
    },
    addZoomEye() {
      const zoomEye = new BMapLib.ZoomEyeControl();
      this.map.addControl(zoomEye);
    },
    simulateRealTimeTrack() {
      // 模拟实时位置更新
      setInterval(() => {
        const newPoint = new BMap.Point(
          this.map.getCenter().lng + 0.005,
          this.map.getCenter().lat + 0.003
        );
        this.map.panTo(newPoint);
        this.overlays.push(newPoint);
        this.drawTrack();
      }, 1000);
    },
    drawTrack() {
      const track = new BMap.Polyline(this.overlays, {
        strokeColor: 'blue',
        strokeWeight: 2,
        strokeOpacity: 0.5
      });
      this.map.addOverlay(track);
    }
  }
};
</script>